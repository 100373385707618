h1 {
  font-size: $heading1;
}
h2 {
  font-size: $heading2;
}
h3 {
  font-size: $heading3;
}

body {
  font-size: 1rem;
  font-family: 'Dosis', sans-serif;
  margin: 0;
  background-color: $darkGray;
  color: #ffffff;
  position: relative;
  min-height: 100vh;
  width: 100%;
  a {
    color: $lightPurple;
  }
  a:visited {
    color: $lightPurple;
  }

  h1 {
    font-size: 4rem;
    text-align: center;
    margin-top: 100px;
  }
  h2 {
    margin-top: 10rem;
    text-align: center;
  }

  //// NAVIGATION ////
  nav {
    width: 100%;
    height: 60px;
    background-color: $darkGray;
    position: fixed;
    top: 0;
    @media (max-width: 1061px) {
      display: none;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 30%;
      position: fixed;
      right: 0;
      margin-top: 0px;
      padding-top: $base-padding * 1.5;
      li {
        a {
          text-decoration: none;
          font-size: 1.5rem;
        }
      }
    }
  }

  main {
    padding-bottom: 100px;
    //// MAIN ////
    /// Home

    //Rotating image section
    @media (max-width: 1061px) {
      #image-wrapper {
        display: none;
      }
      #rotating-image {
        display: none;
      }
    }

    #image-wrapper {
      position: fixed;
      bottom: 2rem;
      z-index: 1;
      left: 1rem;
      display: flex;
    }
    #rotating-image {
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
    }

    .i-am-tove {
      margin: 0 auto;
      width: 90%;
      margin-bottom: 2rem;
      max-width: 850px;

      h1 {
        text-align: left;
      }
      p {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }

    .start-page-layout {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto -5rem;
      gap: 10%;
      width: 90%;
      max-width: 850px;

      .presentation {
        font-size: $heading3;
        margin: 0;
      }
    }
    .competences-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      align-content: center;
      margin-top: 4rem;
      justify-content: space-between;
    }
    @media (min-width: 1060px) {
      .competences-box {
        align-content: flex-start;
      }
    }
    .competence {
      border: 1px solid $lightPurple;
      border-radius: 5px;
      padding: 5px;
      width: fit-content;
      color: $lightPurple;
      background-color: rgb(20, 20, 20);
    }
  }
  .enhanced {
    color: $lightYellow;
  }
  .enhanced-purple {
    color: $lightPurple;
  }

  .portfolio {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .portfolio-image {
    height: 160px;
    width: 100%;
    object-fit: cover;
    width: 350px;
    border: 1px solid rgb(62, 57, 57);
    border-radius: 5px;
  }
  .portfolio-item {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    align-items: center;
    margin-bottom: 3rem;
    max-width: 500px;

    button {
      padding: 0.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      background-color: $darkGray;
      border: 1px solid $darkYellow;
      border-radius: 0.5rem;
      background-color: $lightYellow;
      button:hover {
        cursor: pointer;
      }
    }
    p {
      margin: 0.3rem;
    }
    .about-the-project {
      margin-bottom: 1rem;
      max-width: 350px;
    }
  }

  ///Portfolio

  .portfolio {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .portfolio-image {
    height: 160px;
    width: 100%;
    object-fit: cover;
    width: 350px;
    border: 1px solid rgb(62, 57, 57);
    border-radius: 5px;
  }
  .portfolio-item {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    align-items: center;
    margin-bottom: 3rem;

    button {
      padding: 0.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      background-color: $darkGray;
      border: 1px solid $darkYellow;
      border-radius: 0.5rem;
      background-color: $lightYellow;
      button:hover {
        cursor: pointer;
      }
    }
    p {
      margin: 0.3rem;
    }
    .about-the-project {
      margin-bottom: 1rem;
      max-width: 350px;
    }
  }

  @media screen and (min-width: 1061px) {
    .portfolio-image {
      height: 200px;
      width: 450px;
    }
  }

  //// CV ////

  .experience-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 100px;
    gap: 20px;
    margin: 0 10%;
    .experience-container {
      border: 1px solid $lightPurple;
      border-radius: 7px;
      width: 80%;
      max-width: 300px;
      padding: 1rem;
      box-shadow: 1px 1px 4px #c1b8ff;
      h2 {
        font-size: 1.5rem;
      }
      .work-details {
        font-size: 0.8rem;
        color: $lightYellow;
      }
    }
  }

  ///Contact
  .contact-section {
    display: flex;
    flex-direction: column;
  }

  .contact-info {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .social-media {
    height: 400px;
    width: 100%;
    display: flex;
    margin-top: 3rem;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
  }

  //// FOOTER ////
  footer {
    background-color: $darkGray;
    color: white;
    height: 100px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    p {
      padding-top: $base-padding * 3;
      margin: 0;
    }
  }
}
