.btn-primary {
  background-color: rgb(68, 94, 99);
  border: 1px solid black;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.btn-secondary {
  background-color: rgb(214, 108, 108);
  border: 1px solid black;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
