@import './variables/fonts';
@import './variables/buttons';
@import './variables/paddingsAndMargins';
@import './variables/colors';
@import '_base';

@media screen and (min-width: 768px) {
  @import '_tablet';
}

@media screen and (min-width: 1061px) {
  @import '_desktop';
}
